import { Dispatch, AnyAction } from 'redux';
import { Response, ResponseError } from 'superagent';
import ApiService from '~/utils/ApiService';
import ErrorService from '~/utils/ErrorService';
import { veracodeNavService } from '~/constants/ModelConstants';
import config from '~/config';
import Helpers from '~/utils/Helpers';

import {
  FETCH_VC_NAV_REQUEST,
  FETCH_VC_NAV_SUCCESS,
  FETCH_VC_NAV_FAILURE,
} from '~/actions/vcAppActions/vcAppActionsTypes/types';

export const fetchVeracodeNavRequest = () => ({
  type: FETCH_VC_NAV_REQUEST,
});

export const fetchVeracodeNavSuccess = (response: Response) => ({
  type: FETCH_VC_NAV_SUCCESS,
  navigation: response,
});

export const fetchVeracodeNavFailure = (error: ResponseError) => ({
  type: FETCH_VC_NAV_FAILURE,
  message: error.message || 'Error fetching Veracode navigation data.',
});

export const fetchVeracodeNav = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(fetchVeracodeNavRequest());
  return ApiService
    .getV2(`${config.VERACODE_UIGATEWAY_HOST}${veracodeNavService}?activateId=scans_and_analysis`, {
      withCredentials: true
    })
    .then((response: Response) => {
      dispatch(fetchVeracodeNavSuccess(response));
    })
    .catch((error: ResponseError) => {
      Helpers.logout(window.location.href);
      dispatch(fetchVeracodeNavFailure(error));
      ErrorService.capture('Error fetching Veracode navigation data', error);
    });
};
