import React from 'react';
import BambooPlan from '~/images/bamboo_plan.png';
import TokenControl from '~/containers/TokenControl';
import PowerShellExecutionPolicyStep from '~/components/PowerShellExecutionPolicyStep';
import CIScriptModeTabHeader from '~/components/CIScriptModeTabHeader';
import TabWrapper from '~/components/TabWrapper';
import TabContent from '~/components/TabContent';
import CopyToClipboard from '~/containers/CopyToClipboard';
import RegionHelpers from '~/utils/RegionHelpers';
import config from '~/config';

interface BambooConfigurationProps {
  teamId?: string;
  integrationType: string;
  onScriptTabChange?: (...args: any[]) => any;
  activeScriptMode?: string;
  renderAgentWorkspaceSelection?: (...args: any[]) => any;
  isOrgPage?: boolean;
}

const BambooConfiguration: React.FunctionComponent<BambooConfigurationProps> = props => {
  const { teamId, integrationType, activeScriptMode = 'curl' } = props;

  function handleTabChange(field) {
    props.onScriptTabChange(field);
  }

  return (
    <div className="mb+">
      <div className="font--h6">Step one: Set environment variable</div>
      <p className="mt-">
        Click "Dashboard" {'>'} "All Plans". Select the name of the plan in the list, to get to the
        plan you want to edit and select "Actions" {'>'} "Configure Plan", then set the{' '}
        <code>SRCCLR_API_TOKEN_PASSWORD</code> environment variable to the authentication token below{RegionHelpers.getRegionEnvVarInstruction(config.REGION)}.
      </p>
      <div className="mt-">
        <TokenControl teamId={teamId} integrationType={integrationType} />
      </div>
      <img className="col-1-1 mt-" src={BambooPlan} alt="Bamboo global setup screenshot" />

      {props.renderAgentWorkspaceSelection()}

      <div className="font--h6 mt-">Step two: Configure Atlassian Bamboo</div>
      <br />
      <p>
        {' '}
        Add a "Script" task to the plan you want to add Veracode SCA into, and fill in the following
        fields:
      </p>
      <ul>
        <li>
          <span className="text--bold">Task Description</span> - "Veracode SCA scan"
        </li>
        <li>
          <span className="text--bold">Interpreter</span> - Leave this "Shell"
        </li>
        <li>
          <span className="text--bold">Script location</span> - Leave this "Inline"
        </li>
        <li>
          <span className="text--bold">Argument</span> - Leave blank
        </li>
        <li>
          <span className="text--bold">Environment variables</span> - Add{' '}
          <code>
            SRCCLR_API_TOKEN=$
            {'{'}
            bamboo.SRCCLR_API_TOKEN_PASSWORD
            {'}'}{' '}
          </code>{' '}
          {props.isOrgPage && (
            <span>
              and
              <code>
                SRCCLR_WORKSPACE_SLUG=$
                {'{'}
                bamboo.SRCCLR_WORKSPACE_SLUG
                {'}'}
              </code>
            </span>
          )}
        </li>
        <li>
          <span className="text--bold">Working sub directory</span> - Leave this blank, unless the
          configuration file (i.e. package.json, pom.xml, build.gradle, requirements.txt, etc) is in
          a subdirectory. If it is in a subdirectory, specify the path to the subdirectory.
        </li>
        <li>
          <span className="text--bold">Script body</span>
          <div>
            <TabWrapper className={`mb mt-`}>
              <CIScriptModeTabHeader
                onScriptTabChange={handleTabChange}
                activeScriptMode={activeScriptMode}
              />
              <TabContent field={'curl'} isActive={activeScriptMode === 'curl'}>
                <CopyToClipboard
                  value={`curl -sSL https://sca-downloads.veracode.com/ci.sh | sh`}
                  id={'curl-download-' + integrationType}
                />
              </TabContent>
              <TabContent field={'powershell'} isActive={activeScriptMode === 'powershell'}>
                <div className="mt-- mb">
                  <CopyToClipboard
                    value={`iex ((New-Object System.Net.WebClient).DownloadString('https://sca-downloads.veracode.com/ci.ps1')); srcclr scan`}
                    id={'download-scripts-' + integrationType}
                  />
                </div>
                <div className="mb">
                  <PowerShellExecutionPolicyStep />
                </div>
              </TabContent>
            </TabWrapper>
          </div>
        </li>
      </ul>
      <div className="mt">
        For detailed instructions
        <a
          className="link--obvious link--icon-hover"
          href="https://help.veracode.com/go/t_sc_bamboo_token"
          target="_blank"
        >
          {' visit the docs.'}
          <i className="sci sci__arrow--external-link pl-- va-default" />
        </a>
      </div>
    </div>
  );
};

export default BambooConfiguration;
