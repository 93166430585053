import axios, { InternalAxiosRequestConfig } from 'axios';
import _ from 'lodash';
import cookie from 'react-cookie';

/* eslint-disable import/named */
import moment, { unitOfTime } from 'moment';
import Hashes from 'jshashes';
import momentTimezone from 'moment-timezone';
import * as MODEL from '~/constants/ModelConstants';
import config from '~/config';
import { getNavFooter } from '~/utils/navServiceHelper';
import ErrorService from './ErrorService';
import { Region } from '~/components/RegionState.types';

const Helpers = function () {
  const SHA1 = new Hashes.SHA1();

  // only create a particular template once.
  const compiledTemplateCache = {};

  const getRepoPath = repoName => {
    const repoParts = repoName.split('/');
    repoParts.pop();
    return repoParts.join('/');
  };

  const getIssueFromResponse = response => {
    const {
      content: { issue },
    } = response;
    return issue;
  };

  const getRepoName = repoName => {
    const repoParts = repoName.split('/');
    return repoParts.pop();
  };

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const populateTemplate = (template, data) => {
    let compiled = compiledTemplateCache[template];
    if (!compiled) {
      compiledTemplateCache[template] = _.template(template);
      compiled = compiledTemplateCache[template];
    }

    return compiled(data);
  };

  const prettyEnum = str => {
    if (!str) {
      return str;
    }
    let result = str
      .replace('REPOS', 'REPOSITORIES')
      //.replace('REPO', 'REPOSITORY')
      .replace('VULN_', 'VULNERABILITY_')
      .split('_')
      .map(s => {
        return capFirst(s);
      })
      .join(' ');

    if (result === 'Components') {
      result = 'Libraries';
    }

    return result;
  };

  const matchGrab = (str, regex) => {
    if (!str || !str.match || !regex) {
      return '';
    }

    const result = str.match(regex);

    if (result && result.length && result.length === 2) {
      return result[1];
    }

    return '';
  };

  const smartRound = (num, maxLength) => {
    if (isNaN(num) || isNaN(maxLength)) {
      return num;
    }

    const decimalPlaces = maxLength + 2;
    let tmpRound;
    let tmpRoundStr;

    for (let i = decimalPlaces; i >= 0; i--) {
      tmpRound = _.round(num, i);
      tmpRoundStr = '' + tmpRound;
      if (tmpRoundStr.length <= maxLength) {
        return tmpRound;
      }
    }

    return num;
  };

  const cut = (str, len) => {
    if (str.length > len) return str.substring(0, len) + '...';
    return str;
  };

  const leftCut = (str: string, len: number) => {
    if (str.length > len) return '...' + str.substring(str.length - len, str.length);
    return str;
  };

  //This function takes in the project name 'aa/bb/cc/dd' tokenize it by a delimiter and returns '..cc/dd' by default
  //depthCount is the number of levels deep - 'cc/dd' is depthCount of 2 from right to left
  //isLtr (is left to right) is the direction and is set to false by default to return the path from right to left
  const formatProjectName = (
    projectName: string,
    depthCount = 2,
    ellipsis = true,
    delimiter = '/',
    isLtr = false
  ) => {
    if (!projectName) {
      return 'Not available';
    }

    const projNameArr = projectName ? projectName.split(delimiter) : '';
    if (projNameArr && projNameArr.length > depthCount) {
      let start: number;
      let end: number;
      if (isLtr) {
        start = 0;
        end = depthCount;
      } else {
        start = projNameArr.length - depthCount;
        end = projNameArr.length;
      }

      const resArr = projNameArr.slice(start, end);
      let res = resArr.join(delimiter);

      if (ellipsis) {
        res = isLtr ? res + '...' : '...' + res;
      }
      return res;
    } else {
      return projectName;
    }
  };

  const isProjectNameCut = (projectName: string) => {
    return projectName.includes('...');
  };

  const decode = val => {
    if (decodeURIComponent(val) !== val) {
      return decodeURIComponent(val);
    }

    return val;
  };

  const formatE2E = str => {
    return str && str.replace
      ? str
          .replace(/\W+/g, '-')
          .replace(/([a-z\d])([A-Z])/g, '$1-$2')
          .toLowerCase()
      : '';
  };

  const formatBreakOn = (char, forceNewLine = false) => {
    return str => {
      if (!str) {
        return { __html: str };
      }

      let breakChar = !forceNewLine ? '&#x200b;' : '<br />';

      str = str.replace(/</g, '&lt;');
      str = str.replace(/>/g, '&gt;');

      let regString = char === '.' ? '\\.' : char;

      let regExp = new RegExp(regString, 'g');

      return { __html: str.replace(regExp, `${char}${breakChar}`) };
    };
  };

  const formatBreakOnSlash = formatBreakOn('/');

  const formatBreakOnDot = formatBreakOn('.');

  const formatBreakOnNewLine = formatBreakOn('\n', true);

  const highlightItems = (content, items, cutLen) => {
    if (!content || typeof items !== 'object') {
      return { __html: content };
    }

    content = content.replace(/</g, '&lt;');
    content = content.replace(/>/g, '&gt;');

    items = items.map(function (a) {
      return a.replace(/[^A-Za-z0-9_\s-]/g, '');
    });
    items = items.filter(function (a) {
      return a.length > 0;
    });

    if (cutLen && items.length && content && content.length > cutLen) {
      const test = content;
      let testIndex;
      let foundIndex = -1;

      for (let j = 0; j < items.length; j++) {
        testIndex = test.indexOf(items[j].toLowerCase());
        if (items[j].length > 2 && testIndex !== -1) {
          foundIndex = testIndex;
          break;
        }
      }

      if (foundIndex > -1) {
        if (foundIndex > cutLen) {
          if (foundIndex < content.length - cutLen) {
            content = '...' + content.substr(foundIndex - cutLen / 2, cutLen) + '...';
          } else {
            content = '...' + content.substr(cutLen * -1);
          }
        }
      }
      content = cut(content, cutLen);
    } else if (cutLen && content && content.length > cutLen) {
      content = cut(content, cutLen);
    }

    let html = _.clone(content);

    for (let i = 0; i < items.length; i++) {
      if (items[i].length < 2) {
        continue;
      }
      html = html.replace(
        new RegExp('(' + items[i] + ')', 'gi'),
        '<span class="text--highlight-yellow">$1</span>'
      );
    }

    return { __html: html };
  };

  const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
      let context = this,
        args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  };

  const sha1 = toHash => {
    return SHA1.hex(toHash).slice(0, 9);
  };

  // To suppress the moment deprecation warnings pass the format to the moment constructor
  // see: https://stackoverflow.com/questions/23263380/deprecation-warning-moment-construction-falls-back-to-js-date-when-trying-to

  const formatDate = (dateStr, format = 'D MMM YYYY HH:mmA') => {
    const dateStrWithTimezone = `${moment(dateStr).format(format)} ${getTimezoneAbbr()}`;
    const formattedDateResult = moment(dateStr).isValid() ? dateStrWithTimezone : dateStr;
    return dateStr ? formattedDateResult : 'Unknown';
  };

  const getTimezoneAbbr = () => {
    const possibleTimezone = moment.tz.guess();
    return moment.tz([2012, 0], possibleTimezone).zoneAbbr();
  };

  const formatTime = (dateStr, format = 'HH:mm:ss') => {
    return dateStr ? moment(dateStr).format(format) : 'Unknown';
  };

  const formatTimestamp = (dateStr, format = 'D MMM YYYY HH:mm:ssA', showTimeZone = true) => {
    const timeZone = showTimeZone ? ` ${getTimezoneAbbr()}` : '';
    return dateStr ? `${moment(dateStr).format(format)}${timeZone}` : 'Unknown';
  };

  const formatDateRel = dateStr => {
    return dateStr ? moment(dateStr).fromNow() : 'Unknown';
  };

  const capFirst = str => {
    if (!str) {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const sanitizeLanguageType = languageType => {
    if (!languageType) {
      return '';
    }

    if (languageType === 'RUBY') {
      return 'Ruby';
    } else if (languageType.toLowerCase() === 'js' || languageType.toLowerCase() === 'javascript') {
      return 'JavaScript';
    } else if (languageType === 'JAVA') {
      return 'Java';
    } else if (languageType === 'PYTHON') {
      return 'Python';
    } else if (languageType === 'OBJECTIVEC') {
      return 'Objective-C';
    } else if (languageType === 'SWIFT') {
      return 'Swift';
    } else if (languageType === 'CPP') {
      return 'C/C++';
    } else if (languageType === 'CSHARP') {
      return 'C#';
    }
    return languageType || '--';
  };

  const sanitizePackageManagerType = packageManagerType => {
    if (!packageManagerType) {
      return '';
    }
    const formattedPackagemanager = MODEL.packageManagerTypeMap[packageManagerType.toLowerCase()];
    return formattedPackagemanager || '--';
  };

  const getLicenseRisksFromLicenses = data => {
    let risksArray = [];
    for (let value of Object.values(data)) {
      risksArray.push(capFirst(value.risk));
    }
    return _.uniq(risksArray);
  };

  const getComponentName = comp => {
    return (
      (
        (comp.coordinate1 ? comp.coordinate1 : '') +
        ' ' +
        (comp.coordinate2 ? comp.coordinate2 : '')
      ).trim() +
      ' ' +
      comp.version
    ).trim();
  };

  const generateRefId = (id, name, version) => {
    return [
      id ? id : 'nm',
      name.replace(/\W/g, '-').toLowerCase(),
      version.replace(/\./g, '_'),
    ].join('-');
  };

  const humanReadableLine = ln => {
    if (ln && ln.indexOf('#L') !== -1) {
      const parts = ln.split('#L');
      return 'Line ' + parts[1] + ' of ' + parts[0];
    }

    return ln;
  };

  const isGplString = t => {
    return t && !!(t.match(/\bGPL/i) || t.match(/\(A?GPL/i));
  };

  const getObjectAsListSortedKeyValue = (data, limitTo) => {
    const keys = Object.keys(data || {});
    let result = keys
      .map(function (key) {
        return { label: key, count: data[key], percentOfMax: 0 };
      })
      .sort(function (a, b) {
        return a.count < b.count ? 1 : -1;
      });

    result = result.map(function (r) {
      r.percentOfMax = (r.count / (result[0].count > 0 ? result[0].count : 1)) * 100;
      return r;
    });

    return result.length > limitTo ? result.slice(0, limitTo) : result;
  };

  // NOTE: this helper can only be used to get the org slug on standalone
  const getOrgSlug = (): string => {
    const orgSlugRE = /^([-\w]+)\./;
    const orgMatches = orgSlugRE.exec(window.location.hostname) || [];
    const orgSlug: string = !!orgMatches.length && orgMatches[1] && orgMatches[1].toLowerCase();

    return orgSlug;
  };

  const getOrgId = (org: App.Org) => {
    const { id } = org;
    return id;
  };

  const getTeamId = () => {
    const splittedPath = window.location.pathname.split('/');
    for (let i = 0; i < splittedPath.length; i++) {
      if (splittedPath[i] === 'teams') {
        return splittedPath[i + 1];
      }
    }
    return null;
  };

  /**
    PDVLP-649. Given number of seconds, convert to the appropriate format:
    1) Less than 60 seconds, use seconds
    2) Between 1-60 mins, use minutes
    3) Between 1-24 hours, use hours
    4) Between 1-7 days, use days
    5) Greater than 1 week, use weeks
    6) Greater than a year, show years
    No decimal point, round to the closest whole number
  */
  const getMedianTimeFormat = seconds => {
    let medianUnit, medianLabel;

    const momentDuration = moment.duration(seconds, 'seconds');

    if (seconds <= 60) {
      //point 1
      medianUnit = seconds;
      medianLabel = 'seconds';
    } else if (momentDuration.asHours() <= 1) {
      //point 2
      medianUnit = momentDuration.asMinutes();
      medianLabel = 'minutes';
    } else if (momentDuration.asHours() <= 24) {
      //point 3
      medianUnit = momentDuration.asHours();
      medianLabel = 'hours';
    } else if (momentDuration.asDays() <= 7) {
      //point 4
      medianUnit = momentDuration.asDays();
      medianLabel = 'days';
    } else if (momentDuration.asWeeks() <= 52) {
      //point 5
      medianUnit = momentDuration.asWeeks();
      medianLabel = 'weeks';
    } else {
      //point 6
      medianUnit = momentDuration.asYears();
      medianLabel = 'years';
    }

    // Round them to the nearest whole number
    medianUnit = Math.round(medianUnit);

    // Luckily all the pural form are with an s. If it's 1, we will remove the (s) to be singular.
    if (medianUnit === 1) {
      medianLabel = medianLabel.slice(0, -1);
    }

    return {
      medianUnit,
      medianLabel,
    };
  };

  const formatCost = (priceInCents: number): string => {
    if (!priceInCents) return '';
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });

    return formatter.format(priceInCents / 100);
  };

  const isCommitHash = (s: string): boolean => {
    //http://stackoverflow.com/questions/1896715/how-do-i-check-if-a-string-is-a-valid-md5-or-sha1-checksum-string
    // At this point all commit hash are SHA-1
    if (typeof s === 'string') {
      return !!s.match('[a-fA-F0-9]{40}');
    }
    return false;
  };

  const shortedCommitHash = (s: string): string => {
    return s.slice(0, 7);
  };

  /**
   * Check if the url ends with a regustry-frontend expected hostname
   * @param {String} url to check
   */
  const isRegistryFrontendUrl = url => {
    if (!url || url.length === 0) {
      return false;
    }

    const urlToCheck = new URL(url);
    let isRegistryUrl = false;

    config.REGISTRY_FRONTEND_DOMAIN.forEach(domain => {
      if (urlToCheck.hostname.endsWith(domain)) {
        isRegistryUrl = true;
      }
    });

    return isRegistryUrl;
  };

  const calculateScansUsed = scanUsage => {
    if (!scanUsage.length) return 0;

    const mostRecentMonth = scanUsage.slice(-1)[0].month;
    let runningTotal = 0;

    for (let i = scanUsage.length - 1; i >= 0; i--) {
      if (scanUsage[i].month === mostRecentMonth) {
        runningTotal += scanUsage[i].scans;
      } else {
        break;
      }
    }

    return runningTotal;
  };

  const buildUsageChartData = (scanUsage = []) => {
    const today = moment();
    const daysInCurrentMonth = today.daysInMonth();
    const monthData = [];

    for (let i = 1; i <= daysInCurrentMonth; i++) {
      const matchingScan = scanUsage.find(scanData => {
        return scanData.dayOfMonth === i;
      });
      if (matchingScan) {
        monthData.push(matchingScan);
      } else {
        monthData.push({
          scans: 0,
          dayOfMonth: i,
          year: today.year(),
          month: today.month(),
        });
      }
    }

    return monthData;
  };

  const formatUserName = (user = {}) => {
    const { firstName, lastName, email = '' } = user;

    if (!firstName && !lastName) {
      return email;
    }

    return `${firstName ? firstName : ''}${firstName && lastName ? ' ' : ''}${
      lastName ? lastName : ''
    }`;
  };

  /* Checks if the format of severity is valid
   * Valid if between 0.1 and 10.0 with 1 decimal point max
   */
  const isSeverityValid = severity => {
    if (!isNaN(severity) && severity >= 0.1 && severity <= 10) {
      const regex = /^([0-9]|10)([.][\d]{1})?$/;

      if (regex.test(severity)) {
        return true;
      }
    }

    return false;
  };

  const countDecimals = value => {
    if (!value) {
      return;
    }
    const decimals = value.toString().split('.')[1];
    return (decimals && decimals.length) || 0;
  };

  const getSeverityText = severity => {
    if (severity < 4) {
      return 'Low';
    } else if (severity < 7) {
      return 'Medium';
    } else {
      return 'High';
    }
  };

  const getRepoScanById = (scanHistory = {}, id) => {
    const { _embedded: scanHistoryList = {} } = scanHistory;
    const { repoScans = [] } = scanHistoryList;
    return _.find(repoScans, { id });
  };

  const getLatestRepoScan = (scanHistory = {}) => {
    const { _embedded: scanHistoryList = {} } = scanHistory;
    const { repoScans = [] } = scanHistoryList;
    return repoScans[0];
  };

  const getLatestRepoScanByBranch = (scanHistory = {}, branch) => {
    const { _embedded: scanHistoryList = {} } = scanHistory;
    const { repoScans = [] } = scanHistoryList;
    return _.find(repoScans, { branch });
  };

  /**
   *
   * @param {string} location
   * @param {string} param
   * @param {string} newValue
   *
   * Modifies URL query parameters with the following behaviors:
   * 1. When param supplied is existing, update it with the newValue
   * 2. When param supplied is new, it is appended to query params with the given newValue
   */
  const updateURLQueryParam = (location = '', param, newValue, history = {}) => {
    if (param) {
      const url = new URL(location);
      const { searchParams = {}, pathname } = url;
      searchParams.set(param, newValue);
      history.push(`${pathname}${url.search}`);
    }
  };

  /**
   * @param {string} location
   * @param {array} params
   *
   * Removes URL query parameters from URL location which behaves as follows:
   * 1. Removes all query parameters when params is empty
   * 2. Removes query parameters specified in the params
   */
  const removeURLQueryParams = (location = '', params = [], history = {}) => {
    const url = new URL(location);
    const { searchParams = {}, pathname } = url;

    // If params array is empty, default to delete all params
    if (params.length === 0) {
      for (let entry of searchParams.entries()) {
        const param = entry[0];
        searchParams.delete(param);
      }
    } else {
      params.forEach(param => searchParams.delete(param));
    }

    history.replace(`${pathname}${url.search}`);
  };

  const getFriendlyErrorMsg = err => {
    let userMessage;
    // don't show 500-class error messages, as they're almost always cryptic
    // but 400-class ones _usually_ carry english with them
    // even though 400-class responses don't carry .status, the !== does the right thing
    if (err && err.status <= 500 && err.hasOwnProperty('message')) {
      userMessage = err.message;
    } else {
      // don't set it to `null` as that is used to say a-ok downstream
      userMessage = '';
    }
    return userMessage;
  };

  /**
   * isWorkspaceAdmin
   *
   * @param {object} workspace
   *
   * This helper checks if a workspace is administered by the user.
   * Sandbox, unlike other workspaces, is always administered by the user and typically has membership null;
   * Other workspaces are administered when membership roles include 'WORKSPACE_ADMIN'.
   * Otherwise membership role is always set to just 'USER'.
   */
  const isWorkspaceAdmin = workspace => {
    const membership = workspace.membership || {};
    const { roles = [] } = membership;

    return !roles.length || roles.includes('WORKSPACE_ADMIN');
  };

  const getActiveTeamById = ({
    teams,
    teamId,
  }: {
    teams: App.Team[];
    teamId: string;
  }): App.Team => {
    return teams.find(team => team.id === teamId);
  };

  const hasManageAgentPermissions = ({
    teams,
    teamId,
  }: {
    teams: App.Team | App.Team[];
    teamId?: string;
  }): boolean => {
    // if we have an array of teams, we find the active team by Id
    // otherwise, the activeTeam is the single team object
    const activeTeam = _.isArray(teams) ? getActiveTeamById({ teams, teamId }) : teams;
    return activeTeam?.permissions?.manageAgents || false;
  };

  const hasReportPermission = ({
    teams,
    teamId,
  }: {
    teams: App.Team | App.Team[];
    teamId?: string;
  }): boolean => {
    // if we have an array of teams, we find the active team by Id
    // otherwise, the activeTeam is the single team object
    const activeTeam = _.isArray(teams) ? getActiveTeamById({ teams, teamId }) : teams;

    return activeTeam?.permissions?.reports || false;
  };

  const hasIgnoreIssueChangesPermission = ({
    teams,
    teamId,
  }: {
    teams: App.Team | App.Team[];
    teamId?: string;
  }): boolean => {
    // if we have an array of teams, we find the active team by Id
    // otherwise, the activeTeam is the single team object
    const activeTeam = _.isArray(teams) ? getActiveTeamById({ teams, teamId }) : teams;
    return activeTeam?.permissions?.ignoreIssueChanges || false;
  };

  const hasThirdPartyIssuePermission = ({
    teams,
    teamId,
  }: {
    teams: App.Team | App.Team[];
    teamId?: string;
  }): boolean => {
    // if we have an array of teams, we find the active team by Id
    // otherwise, the activeTeam is the single team object
    const activeTeam = _.isArray(teams) ? getActiveTeamById({ teams, teamId }) : teams;
    return activeTeam?.permissions?.createThirdPartyIssues || false;
  };

  const getPortfolioLinkFromMyState = myState => {
    const {
      me: { organization },
    } = myState;

    const {
      _links: { portfolio },
    } = organization;

    const { href } = portfolio;
    return href;
  };

  const getIssueDataLinkFromReportIssuesState = reportIssuesState => {
    const {
      issueFixData: { issue },
    } = reportIssuesState;
    const {
      _links: { issueData },
    } = issue;
    const { href } = issueData;
    return href;
  };

  const validateOrgName = (name: string) => {
    return !name || /^[a-zA-Z]{1}[a-zA-Z0-9\s\-_]{2,19}$/.test(name);
  };

  const validateRepoName = (name: string) => {
    return !name || /^[a-zA-Z0-9]{1}[a-zA-Z0-9@:/.\-_]{2,39}$/.test(name);
  };

  const validateWorkspaceName = (name: string) => {
    return !name || /^[a-zA-Z]{1}[a-zA-Z0-9\s\-_]{2,19}$/.test(name);
  };

  const validateTeamName = (name: string) => {
    return !name || /^[a-zA-Z0-9]{1}[a-zA-Z0-9_-]{2,19}$/.test(name);
  };

  const validateAgentName = (name: string) => {
    return !name || /^[a-zA-Z0-9]{1}[a-zA-Z0-9_-]{2,19}$/.test(name);
  };

  const getUrlByPermission = (teamId, permissions, repoCount) => {
    const {
      listGroups,
      manageAgents,
      reports,
      update,
      delete: deleteTeams,
      updatePolicy,
      notifications,
    } = permissions;
    const WORKSPACE_TEAMID = `/workspaces/${teamId}`;
    if (reports) {
      return `${WORKSPACE_TEAMID}/issues`;
    } else if (manageAgents) {
      if (repoCount) {
        return `${WORKSPACE_TEAMID}/agents`;
      } else {
        return `${WORKSPACE_TEAMID}/agents/new`;
      }
    } else if (update || deleteTeams) {
      return `${WORKSPACE_TEAMID}/settings`;
    } else if (listGroups) {
      return `${WORKSPACE_TEAMID}/user-management/teams`;
    } else if (updatePolicy) {
      return `${WORKSPACE_TEAMID}/rules`;
    } else if (notifications) {
      return `${WORKSPACE_TEAMID}/notifications`;
    }
  };

  const formattedVcId = (id: string) => /[^-]*$/.exec(id)[0];

  const getVcUserLastLoginFromNav = navigation => {
    const { activity_timestamp: activityTimestamp = '' } = getNavFooter(navigation);
    return momentTimezone(new Date(parseInt(activityTimestamp)))
      .tz('America/New_York')
      .format('M/D/YY h:mm A z');
  };

  const isProxyConnected = (lastConnected: string): boolean => {
    const lastConnectedDate = moment(lastConnected);
    const duration = moment.duration(moment(new Date()).diff(lastConnectedDate));
    // unary + operator converts operand to number and satisfies type checker
    return (
      +duration.asMilliseconds() > 0 &&
      +duration.asMilliseconds() < MODEL.PROXY_CONNECTED_GRACE_PERIOD
    );
  };

  /**
   * When in Veracode domain, checkVeracodeSessionTimeout will simply check if session is valid.
   * If session is valid, just pass the request along. Otherwise, return a reject which
   * would prevent identity from being ping-ed. We don't want to ping identity when session is invalid,
   * as it will remove the vsid token. The vsid token needs to be preserved if we want to successfully
   * redirect to MODEL.veracodeLogout.
   * @param requestConfig
   * @returns either the requestConfig or a reject.
   */
  async function checkVeracodeSessionTimeout(requestConfig: InternalAxiosRequestConfig) {
    // use `axios` directly here because ApiService calls this helper function and we shoulnd't
    // have a recursive dependency on ApiService
    return await axios
      .get(`${config.VERACODE_UIGATEWAY_HOST}${MODEL.veracodeAuthSessionUrl}`, requestConfig)
      .then(res => {
        const sessionTimeout = res?.data.sessionTimeout;
        const isSessionValid = moment().isBefore(sessionTimeout);

        if (isSessionValid) {
          cookie.save(MODEL.VERACODE_SESSION_TIMEOUT_COOKIE_KEY, sessionTimeout, {
            path: '/',
          });

          return requestConfig;
        } else {
          clearSessionTimeoutCookieAndRedirect();
          return Promise.reject('Request rejected');
        }
      })
      .catch(error => {
        ErrorService.capture('Error fetching data from Veracode UIGateway', error);
        clearSessionTimeoutCookieAndRedirect();

        return Promise.reject('Request rejected');
      });
  }

  function clearSessionTimeoutCookieAndRedirect() {
    cookie.remove(MODEL.VERACODE_SESSION_TIMEOUT_COOKIE_KEY);
    logout(window.location.href);
  }

  const hasEnforceOrgLevelRulesPermissions = myState => {
    const {
      me: {
        organization: {
          permissions: { readPolicy, updatePolicy },
        },
      },
    } = myState;

    return readPolicy && updatePolicy;
  };

  const getPolicyControlToggleModalId = mode =>
    (mode => {
      switch (mode) {
        case 'DEFAULT':
          return 'USE_DEFAULT_POLICY';
        case 'INHERIT':
          return 'USE_ORGANIZATION_POLICY';
        case 'CUSTOM':
          return 'USE_CUSTOM_POLICY';
        default:
          throw 'Base policy status';
      }
    })(mode);

  // all toggles enabled if enforceRules is OFF or enforceRules is ON and the WS is in exclusion list (i.e. WS is allowed to toggle its own policy status)
  // all toggles disabled if enforceRules is ON and WS is not in exclusion list
  const shouldEnableToggleButton = ({ enforceRules, scope, scopeTeams }, teamId: number) =>
    !enforceRules ||
    (enforceRules && scope === 'EXCLUDE' && scopeTeams.some(scopeTeam => scopeTeam === teamId));

  // This is used in the Policy control card list to determine if the control(rule) consists any GPL or Multi license kind.
  const existingGplMultiPolicies = control => {
    if (control.condition.descriptor.parameters.kind == 'gpl') {
      return 'gpl';
    } else if (control.condition.descriptor.parameters.kind == 'multi') {
      return 'multi';
    }
    return 'none';
  };
  function getWorkspaceScanDate(option: string) {
    const [period, duration, unit] = option.split('_');
    // handle all 'Last x days | months'
    if (period === 'last') {
      return moment()
        .subtract(duration, <unitOfTime.DurationConstructor>unit)
        .format();
    } // handle 'More than 7 days'
    else if (period === 'more') {
      return moment().subtract(1, 'week').subtract(1, 'day').format();
    } // handle 'All dates'
    else {
      // If null is passed, BE will give data for all time (All dates)
      return null;
    }
  }

  const outOfScanDateRange = (reportType: string, issueType: string[]) => {
    const formattedIssueType = MODEL.issueTypesOptions
      .find(option => option.value === issueType[0])
      ?.label.toLowerCase();
    return `No ${formattedIssueType} found. Change or remove filters to view ${reportType.toLowerCase()}.`;
  };

  const hasScanDateEnabled = () => config.VC_FEATURES.find(feature => feature.scanDate);

  const hasPolicyRiskEnabled = () => config.VC_FEATURES.find(feature => feature.policyRisk);

  const hasLatestScanEnabled = () => config.VC_FEATURES.find(feature => feature.latestScan);

  const hasLibraryCatalogEnabled = () => config.VC_FEATURES.find(feature => feature.libraryCatalog);

  const isScaPlatformUIEnabled = () => config.VC_FEATURES.find(feature => feature.scaPlatformUI);

  const getRiskByKind = (kindValue: string): string => {
    let riskType = '';
    switch (kindValue) {
      case 'high-risk':
        riskType = MODEL.licenseRisksMap.high.type;
        break;
      case 'medium-risk':
        riskType = MODEL.licenseRisksMap.medium.type;
        break;
      case 'low-risk':
        riskType = MODEL.licenseRisksMap.low.type;
        break;
      case 'non-oss':
        riskType = MODEL.licenseRisksMap.unknown.type;
        break;
    }
    return riskType;
  };

  const getRegionActivateCommand = (region: string) => {
    if (!region) {
      return '';
    }

    switch (region) {
      case Region.ER:
        return '--region ER';
      case Region.FED:
        return '--region FED';
      default:
        return '';
    }
  };

  const logout = (url?: string): void => {
    let logoutExtension = '';
    if (url) {
      logoutExtension = '=' + encodeURIComponent(url);
    }
    const redirectUrl = `${config.VERACODE_LOGIN_HOST}${MODEL.veracodeLogout}${logoutExtension}`;

    // Redirect the user
    window.location.href = redirectUrl;
  };
  

  return {
    isSeverityValid,
    buildUsageChartData,
    formatUserName,
    calculateScansUsed,
    getRepoPath,
    getRepoName,
    numberWithCommas,
    populateTemplate,
    prettyEnum,
    matchGrab,
    smartRound,
    getOrgSlug,
    cut,
    leftCut,
    decode,
    formatE2E,
    formatBreakOnSlash,
    formatBreakOnDot,
    formatBreakOnNewLine,
    highlightItems,
    debounce,
    sha1,
    formatDate,
    formatTime,
    formatTimestamp,
    formatDateRel,
    formatProjectName,
    formatCost,
    isProjectNameCut,
    capFirst,
    sanitizeLanguageType,
    sanitizePackageManagerType,
    getComponentName,
    getFriendlyErrorMsg,
    generateRefId,
    humanReadableLine,
    isGplString,
    getObjectAsListSortedKeyValue,
    getOrgId,
    getTeamId,
    getMedianTimeFormat,
    isCommitHash,
    shortedCommitHash,
    isRegistryFrontendUrl,
    countDecimals,
    getIssueFromResponse,
    getSeverityText,
    getRepoScanById,
    getLatestRepoScan,
    getLatestRepoScanByBranch,
    updateURLQueryParam,
    removeURLQueryParams,
    isWorkspaceAdmin,
    getPortfolioLinkFromMyState,
    getIssueDataLinkFromReportIssuesState,
    hasManageAgentPermissions,
    hasReportPermission,
    hasThirdPartyIssuePermission,
    hasIgnoreIssueChangesPermission,
    validateOrgName,
    validateRepoName,
    validateWorkspaceName,
    validateTeamName,
    validateAgentName,
    getUrlByPermission,
    formattedVcId,
    getVcUserLastLoginFromNav,
    isProxyConnected,
    checkVeracodeSessionTimeout,
    hasEnforceOrgLevelRulesPermissions,
    getPolicyControlToggleModalId,
    getTimezoneAbbr,
    shouldEnableToggleButton,
    existingGplMultiPolicies,
    getActiveTeamById,
    getWorkspaceScanDate,
    outOfScanDateRange,
    hasScanDateEnabled,
    hasPolicyRiskEnabled,
    getRiskByKind,
    getLicenseRisksFromLicenses,
    hasLatestScanEnabled,
    hasLibraryCatalogEnabled,
    getRegionActivateCommand,
    isScaPlatformUIEnabled,
    logout,
  };
};

export default Helpers();
