import React from 'react';
import PipelinesAccount from '~/images/pipelines_account.png';
import TokenControl from '~/containers/TokenControl';
import PowerShellExecutionPolicyStep from '~/components/PowerShellExecutionPolicyStep';
import CIScriptModeTabHeader from '~/components/CIScriptModeTabHeader';
import TabWrapper from '~/components/TabWrapper';
import TabContent from '~/components/TabContent';
import RegionHelpers from '~/utils/RegionHelpers';
import config from '~/config';

interface PipelinesConfigurationProps {
  teamId?: string;
  integrationType: string;
  onScriptTabChange?: (...args: any[]) => any;
  activeScriptMode?: string;
  renderAgentWorkspaceSelection?: (...args: any[]) => any;
}

const PipelinesConfiguration: React.FunctionComponent<PipelinesConfigurationProps> = props => {
  const { teamId, integrationType, activeScriptMode = 'curl' } = props;

  function handleTabChange(field) {
    props.onScriptTabChange(field);
  }

  return (
    <div className="mb+">
      <div className="font--h6">Step one: Set environment variable</div>
      <p className="mt-">
        Go to the repository you want to scan and then select Settings {'>'} Pipelines {'>'}{' '}
        Environment variables, then set the <code>SRCCLR_API_TOKEN</code> environment variable to
        the authentication token below{RegionHelpers.getRegionEnvVarInstruction(config.REGION)}.
      </p>
      <div className="mt-">
        <TokenControl teamId={teamId} integrationType={integrationType} />
      </div>
      <img className="col-1-1 mt-" src={PipelinesAccount} alt="Pipeline plan setup screenshot" />
      {props.renderAgentWorkspaceSelection()}

      <div className="font--h6 mt-">Step two: Configure Pipelines</div>
      <p>
        In order to scan using Veracode SCA, add the following to the last "default" configuration
        script command in the <code>bitbucket-pipelines.yml</code> file:
      </p>

      <TabWrapper className={`mb`}>
        <CIScriptModeTabHeader
          onScriptTabChange={handleTabChange}
          activeScriptMode={activeScriptMode}
        />
        <TabContent field={'curl'} isActive={activeScriptMode === 'curl'}>
          <pre>
            pipelines:
            <br />
            {'  '}
            default:
            <br />
            {'  '}
            {'  '}- step:
            <br />
            {'  '}
            {'  '}
            {'  '}
            script:
            <br />
            {'  '}
            {'  '}
            {'  '}
            {'  '}- {`curl -sSL https://sca-downloads.veracode.com/ci.sh | sh`}
          </pre>
        </TabContent>
        <TabContent field={'powershell'} isActive={activeScriptMode === 'powershell'}>
          <pre className="overflow-x--scroll">
            pipelines:
            <br />
            {'  '}
            default:
            <br />
            {'  '}
            {'  '}- step:
            <br />
            {'  '}
            {'  '}
            {'  '}
            script:
            <br />
            {'  '}
            {'  '}
            {'  '}
            {'  '}-{' '}
            {`iex ((New-Object
            System.Net.WebClient).DownloadString('https://sca-downloads.veracode.com/ci.ps1')); srcclr scan`}
          </pre>
          <div className="mb">
            <PowerShellExecutionPolicyStep />
          </div>
        </TabContent>
      </TabWrapper>

      <div className="mt">
        For detailed instructions
        <a
          className="link--obvious link--icon-hover"
          href="https://help.veracode.com/go/t_sc_bitbucket_token"
          target="_blank"
        >
          {' visit the docs.'}
          <i className="sci sci__arrow--external-link pl-- va-default" />
        </a>
      </div>
    </div>
  );
};

export default PipelinesConfiguration;
