import React from 'react';
import CodeshipScreenShot from '~/images/codeship_env.png';
import TokenControl from '~/containers/TokenControl';
import CopyToClipboard from '~/containers/CopyToClipboard';
import PowerShellExecutionPolicyStep from '~/components/PowerShellExecutionPolicyStep';
import CIScriptModeTabHeader from '~/components/CIScriptModeTabHeader';
import TabWrapper from '~/components/TabWrapper';
import TabContent from '~/components/TabContent';
import RegionHelpers from '~/utils/RegionHelpers';
import config from '~/config';

interface CodeshipConfigurationProps {
  teamId?: string;
  integrationType: string;
  onScriptTabChange?: (...args: any[]) => any;
  activeScriptMode?: string;
  renderAgentWorkspaceSelection?: (...args: any[]) => any;
}

const CodeshipConfiguration: React.FunctionComponent<CodeshipConfigurationProps> = props => {
  const { teamId, integrationType, activeScriptMode = 'curl' } = props;

  function handleTabChange(field) {
    props.onScriptTabChange(field);
  }

  return (
    <div className="mb+">
      <div className="font--h6">Step one: Set environment variable</div>
      <p className="mt-">
        Select the project you wish to scan from your Codeship environment {'>'} "Project Settings"{' '}
        {'>'}
        "Environment Variables", then set the <code>SRCCLR_API_TOKEN</code> environment variable to
        the authentication token below{RegionHelpers.getRegionEnvVarInstruction(config.REGION)}.
      </p>
      <div className="mt-">
        <TokenControl teamId={teamId} integrationType={integrationType} />
      </div>
      <img className="col-1-1 mt-" src={CodeshipScreenShot} alt="Travis setup screenshot" />
      {props.renderAgentWorkspaceSelection()}

      <div className="font--h6 mt-">Step two: Configure Codeship</div>

      <TabWrapper className={`mb mt--`}>
        <CIScriptModeTabHeader
          onScriptTabChange={handleTabChange}
          activeScriptMode={activeScriptMode}
        />
        <TabContent field={'curl'} isActive={activeScriptMode === 'curl'}>
          <p>
            Within the test pipelines commands for your repo, after your build commands, enter the
            following code:
          </p>
          <CopyToClipboard
            value={`curl -sSL https://sca-downloads.veracode.com/ci.sh | sh`}
            id={'curl-download-' + integrationType}
          />
        </TabContent>
        <TabContent field={'powershell'} isActive={activeScriptMode === 'powershell'}>
          <div className="mt-- mb">
            <p>
              Within the test pipelines commands for your repo, after your build commands, enter the
              following code:
            </p>
            <CopyToClipboard
              value={`iex ((New-Object System.Net.WebClient).DownloadString('https://sca-downloads.veracode.com/ci.ps1')); srcclr scan`}
              id={'download-scripts-' + integrationType}
            />
          </div>
          <div className="mb">
            <PowerShellExecutionPolicyStep />
          </div>
        </TabContent>
      </TabWrapper>

      <div className="mt">
        For detailed instructions
        <a
          className="link--obvious link--icon-hover"
          href="https://help.veracode.com/go/t_sc_codeship_token"
          target="_blank"
        >
          {' visit the docs.'}
          <i className="sci sci__arrow--external-link pl-- va-default" />
        </a>
      </div>
    </div>
  );
};

export default CodeshipConfiguration;
