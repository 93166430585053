import React from 'react';
import TokenControl from '~/containers/TokenControl';
import JenkinsScreenshot from '~/images/jenkins_binding.png';
import CopyToClipboard from '~/containers/CopyToClipboard';
import PowerShellExecutionPolicyStep from '~/components/PowerShellExecutionPolicyStep';
import CIScriptModeTabHeader from '~/components/CIScriptModeTabHeader';
import TabWrapper from '~/components/TabWrapper';
import TabContent from '~/components/TabContent';
import RegionHelpers from '~/utils/RegionHelpers';
import config from '~/config';

interface JenkinsInstallStepsProps {
  teamId?: string;
  integrationType: string;
  onScriptTabChange?: (...args: any[]) => any;
  activeScriptMode?: string;
  renderAgentWorkspaceSelection?: (...args: any[]) => any;
}

const JenkinsInstallSteps: React.FunctionComponent<JenkinsInstallStepsProps> = props => {
  const { teamId, integrationType, activeScriptMode = 'curl' } = props;

  function handleTabChange(field) {
    props.onScriptTabChange(field);
  }

  return (
    <div className="mb+">
      <div className="font--h6">Step one: Set environment variable</div>
      <p className="mt-">
        Using the{' '}
        <a
          className="link--obvious"
          href="https://wiki.jenkins-ci.org/display/JENKINS/Credentials+Binding+Plugin"
        >
          Credentials Binding Plugin
        </a>
        , create a "Secret text" Global credential using the API token below. Use{' '}
        <code>SRCCLR_API_TOKEN</code> for the ID{RegionHelpers.getRegionEnvVarInstruction(config.REGION)}.
      </p>
      <div className="mt-">
        <TokenControl teamId={teamId} integrationType={integrationType} />
      </div>
      <img className="col-1-1 mt" src={JenkinsScreenshot} alt="Jenkins setup screenshot" />
      {props.renderAgentWorkspaceSelection()}

      <div className="font--h6 mt-">Step two: Configure Jenkins</div>
      <br />
      <p>
        Add the <code>SRCCLR_API_TOKEN</code> "Binding" to the job you want to add Veracode SCA into
        and perform the following:
      </p>

      <TabWrapper className={`mb`}>
        <CIScriptModeTabHeader
          onScriptTabChange={handleTabChange}
          activeScriptMode={activeScriptMode}
        />
        <TabContent field={'curl'} isActive={activeScriptMode === 'curl'}>
          <p>In the "Execute shell" build step, enter the following command</p>
          <CopyToClipboard
            value={`curl -sSL https://sca-downloads.veracode.com/ci.sh | sh`}
            id={'curl-download-' + integrationType}
          />
        </TabContent>
        <TabContent field={'powershell'} isActive={activeScriptMode === 'powershell'}>
          <div className="mt-- mb">
            <p>In the "Execute shell" build step, enter the following command</p>
            <CopyToClipboard
              value={`iex ((New-Object System.Net.WebClient).DownloadString('https://sca-downloads.veracode.com/ci.ps1')); srcclr scan`}
              id={'download-scripts-' + integrationType}
            />
          </div>
          <div className="mb">
            <PowerShellExecutionPolicyStep />
          </div>
        </TabContent>
      </TabWrapper>
      <div>
        For detailed instructions{' '}
        <a className="link--obvious" href="https://help.veracode.com/go/c_sc_jenkins_prereq">
          visit the docs
        </a>
        .
      </div>
    </div>
  );
};

export default JenkinsInstallSteps;
