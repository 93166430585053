import React from 'react';
import * as MODEL from '~/constants/ModelConstants';
import CopyToClipboard from '~/containers/CopyToClipboard';
import DownloadOptionsSwitcher from '~/components/DownloadOptionsSwitcher';

interface DownloadOptionsProps {
  operatingSystem?: string;
  activeInstallOption: string;
  integrationType?: string;
  upgrade?: boolean;
  toggleDownloadOption: (option: string) => any;
}

const DownloadOptions: React.FunctionComponent<DownloadOptionsProps> = props => {
  const { activeInstallOption, integrationType, upgrade } = props;
  const options = upgrade ? MODEL.upgradeOptions : MODEL.downloadOptions;
  const trackOptions: {
    event: string;
    options?: {
      [key: string]: any;
    };
  } = {
    event: 'Install Click',
  };

  return (
    <div>
      <div className="col-4-5 flex" data-automation-id="DownloadOptions-PackageManager">
        <DownloadOptionsSwitcher
          operatingSystem={props.operatingSystem}
          activeInstallOption={props.activeInstallOption}
          toggleDownloadOption={props.toggleDownloadOption}
        />
      </div>
      <div
        className={
          'col-1-1 flex flex--flex-direction--column mt-' +
          (activeInstallOption === 'download' ? '' : ' invisible')
        }
      >
        <div className="grid">
          <div className="grid__item col-1-2">
            <div>OSX:</div>
            <a
              href="https://sca-downloads.veracode.com/srcclr-latest-macosx.tgz"
              className="btn--primary btn ph- pv-- flex flex--justify-content--space-between col-3-5 mt-"
              download
            >
              <i className="sci sci__data--download" />
              <span>Download Agent</span>
            </a>
          </div>
          <div className="grid__item col-1-2">
            <div>Linux:</div>
            <a
              href="https://sca-downloads.veracode.com/srcclr-latest-linux.tgz"
              className="btn--primary btn ph- pv-- flex flex--justify-content--space-between col-3-5 mt-"
              download
            >
              <i className="sci sci__data--download" />
              <span>Download Agent</span>
            </a>
          </div>
        </div>
      </div>
      {options.map(option => {
        const { commands = [], name } = option;

        if (name !== activeInstallOption) {
          return null;
        }

        return (
          <div key={`download-option-${name}`} className="col-1-1 flex flex-direction--column">
            {commands.map((command, index) => {
              const { title, value } = command;
              if (integrationType) {
                trackOptions.options = {
                  integrationType: integrationType.toUpperCase(),
                  type: option.name,
                  item: index + 1,
                };
              }

              return (
                <div key={`id-${index}`} className="">
                  {title && <div className="mt--">{title}</div>}
                  <div className="mt--">
                    <CopyToClipboard
                      value={value}
                      id={`${name}-${index}`}
                      trackOptions={trackOptions}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default DownloadOptions;
